import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { ref } from 'vue';
import { useRootAppConfig } from './config.hooks';

let client: SupabaseClient | undefined;

export const useSupabase = () => {
    const config = useRootAppConfig();
    if (!client) {
        client = createClient(config.value.SUPABASE_PROJECT_URL, config.value.SUPABASE_API_KEY);
    }

    return ref(client);
};
