export default {
    general: {
        buttonActionCancel: 'Cancel',
        buttonActionSubmit: 'Submit',
    },
    authorization: {
        enterViaOnelogin: 'Enter via OneLogin',
        emailAndPassword: 'Email And Password',
        externalUsersOnly: 'external users only',
        emailInputPlaceholder: 'Your email',
        passwordInputPlaceholder: 'Your password',
        errorNoEmailPassword: 'Please Provide Email And Password',
        errorWrongEmailPassword: 'Wrong Email or Password provided',
    },
};
