import { fetchDurableJob, fetchDurableJobs, fetchDurableJobsList, fetchUnseenDurableJobs } from '@/api/durable-jobs.api';
import { DurableJobExpandOpts, DurableJobStatus, type IDurableJob, type IFetchDurableJobsParams } from '@condo/domain';
import { Row, Shape, ShapeStream } from '@electric-sql/client';
import { useQuery } from '@tanstack/vue-query';
import { snakeCase } from 'change-case';
import { camelCase, mapKeys, uniqBy } from 'lodash-es';
import { type MaybeRef, Ref, computed, onBeforeUnmount, onMounted, ref, unref } from 'vue';
import { useRootAppConfig } from './config.hooks';
import { useSupabase } from './supabase.hooks';
import { useSelfUser } from './user.hooks';

export const useDurableJobs = (paramsOrRef?: MaybeRef<IFetchDurableJobsParams>) => {
    const params = computed(() => unref(paramsOrRef));
    return useQuery(['durable-jobs', params], async () => fetchDurableJobs(params.value), {
        initialData: {
            durableJobs: [],
            total: 0,
        },
        refetchInterval: 5_000,
    });
};

export const useDurableJob = (idOrRef?: MaybeRef<number>) => {
    const id = computed(() => unref(idOrRef));
    return useQuery(['durable-job-by-id', id], async () => fetchDurableJob(id.value), {
        initialData: null,
    });
};

export const useUnseenDurableJobs = (expand?: DurableJobExpandOpts): [Ref<IDurableJob[]>, () => Promise<any>] => {
    const config = useRootAppConfig();
    if (config.value.JOBS_PROVIDER === 'supabase') {
        console.log('[Durable Jobs] Using supabase');
        const { subscription, jobs } = useSupabaseJobs(expand);

        onBeforeUnmount(() => {
            subscription.unsubscribe();
        });

        return [jobs, () => Promise.resolve()];
    }
    if (config.value.JOBS_PROVIDER === 'electric') {
        console.log('[Durable Jobs] Using electricsql');
        const { subscription, jobs } = useElectricJobs();

        onBeforeUnmount(() => {
            subscription.unsubscribe();
        });

        return [jobs, () => Promise.resolve()];
    }

    console.log('[Durable Jobs] Using polling');
    const { data, refetch } = useQuery(['durable-jobs', 'unseen'], async () => fetchUnseenDurableJobs(expand), {
        initialData: {
            durableJobs: [],
            total: 0,
        },
        refetchInterval: 15_000,
    });

    const durableJobs = computed(() => data.value.durableJobs);
    return [durableJobs, refetch];
};

export const useDurableJobsList = () => {
    return useQuery(['durable-jobs', 'list'], async () => fetchDurableJobsList(), {
        initialData: [],
        refetchInterval: 5_000,
    });
};

const notificationStatuses = [DurableJobStatus.pending, DurableJobStatus.fail, DurableJobStatus.success];

export const useElectricJobs = () => {
    const user = useSelfUser();
    const config = useRootAppConfig();
    const jobs = ref<IDurableJob[]>([]);

    if (!user.value?.userId) {
        throw new Error(`Must have userId attached to self-user to watch jobs`);
    }
    if (!config.value.S_ELECTRIC_URL) {
        throw new Error(`Must have S_ELECTRIC_URL defined in config when using electric-sql`);
    }

    // @todo it somehow doesnt consistently work w/ --> AND "status" IN (${notificationStatuses.map(s => `'${s}'`).join(',')})
    const stream = new ShapeStream<Row<IDurableJob>>({
        url: `${config.value.S_ELECTRIC_URL}/v1/shape`,
        headers: {
            Authorization: `Basic ${btoa(`${config.value.ELECTRIC_USERNAME}:${config.value.ELECTRIC_PASSWORD}`)}`,
        },
        params: {
            table: `__durable_jobs`,
            where: `created_by='${user.value.userId}' AND seen_at IS NULL`,
        },
    });
    const shape = new Shape(stream);

    const unsubscribe = shape.subscribe(payload => {
        if (!payload.rows.length) {
            jobs.value = [];
            return;
        }
        const newJobs = payload.rows.map(m => mapKeys(m as Record<string, any>, (_k, v) => camelCase(v)) as IDurableJob);
        jobs.value = newJobs.filter(j => notificationStatuses.includes(j.status) && !j.seenAt);
    });

    return { subscription: { unsubscribe }, jobs };
};

export const useSupabaseJobs = (expand?: DurableJobExpandOpts) => {
    const supabase = useSupabase();
    const user = useSelfUser();
    const jobs = ref<IDurableJob[]>([]);

    if (!user.value?.userId) {
        throw new Error(`Must have userId attached to self-user to watch jobs`);
    }

    onMounted(async () => {
        const jobRows = await supabase.value
            .from('__durable_jobs')
            .select(expand?.select?.length ? expand.select.map(snakeCase).join(',') : '*')
            .eq('created_by', user.value.userId)
            .is('seen_at', null)
            .order('created_at', { ascending: false })
            .in('status', notificationStatuses);

        jobs.value = jobRows.data.map(row => mapKeys(row as Record<string, any>, (_k, v) => camelCase(v)) as IDurableJob);
    });

    const subscription = supabase.value
        .channel(`schema-db-changes-id=${user.value.userId}`)
        .on(
            'postgres_changes',
            {
                event: '*',
                schema: 'public',
                table: '__durable_jobs',
                filter: `created_by=eq.${user.value.userId}`,
            },
            payload => {
                const job = mapKeys(payload.new as Record<string, any>, (_k, v) => camelCase(v)) as IDurableJob;

                if (job.seenAt) {
                    jobs.value = jobs.value.filter(j => j.id !== job.id);
                    return;
                }

                if (!notificationStatuses.includes(job.status)) {
                    return;
                }

                jobs.value = uniqBy([job, ...jobs.value], 'id');
            },
        )
        .subscribe();

    return { subscription, jobs };
};
