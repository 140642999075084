import { saveRecentPageVisit } from '@/api/recent-page.api';
import { IMenuList } from '@/types';
import { IEstate, IInvoice, IMFH, IRecentlyVisitedPageDto, VisitedPageType } from '@condo/domain';
import { Ref, ref } from 'vue';
import { useRootAppConfig } from './config.hooks';
import { useStorage } from './util.hooks';

export const useRecentPageTracker = (menuOptions: Ref<IMenuList[]> = ref([])) => {
    const flushThreshold = 5;
    const config = useRootAppConfig();

    const flush = async () => {
        const [recentPages, setRecentPages] = useStorage<IRecentlyVisitedPageDto[]>('RECENT_PAGES');

        if (recentPages.value?.length > flushThreshold) {
            try {
                if (config.value?.F_LOG_RECENT_PAGES) {
                    await saveRecentPageVisit(recentPages.value);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setRecentPages([]);
            }
        }
    };

    const findMenuGroupAndLabel = (menu: IMenuList[], routeName: string): [string, string] | null => {
        for (const group of menu) {
            for (const item of group.items) {
                if (item.to.name === routeName) {
                    return [group.groupName, item.label];
                }
            }
        }
        return null;
    };

    const logEstatePage = (route, estate: Ref<IEstate>) => {
        const [recentPages, setRecentPages] = useStorage<IRecentlyVisitedPageDto[]>('RECENT_PAGES');

        const labels = findMenuGroupAndLabel(menuOptions.value, route.name);

        if (labels?.length) {
            const recentPagesValue = recentPages.value ?? [];
            recentPagesValue.push({
                type: VisitedPageType.estate,
                url: route.fullPath,
                route: {
                    name: route.name,
                    params: route.params,
                    query: route.query,
                },
                visitedMenus: labels,
                data: {
                    estate: {
                        estateId: estate.value?.estateId,
                        condoUuid: estate.value?.condoUuid,
                    },
                },
                visitedAt: Date.now(),
            });
            setRecentPages(recentPagesValue);
        }
        flush();
    };

    const logMfhPage = (route, mfh: Ref<IMFH>) => {
        const [recentPages, setRecentPages] = useStorage<IRecentlyVisitedPageDto[]>('RECENT_PAGES');

        const labels = findMenuGroupAndLabel(menuOptions.value, route.name);

        if (labels?.length) {
            const recentPagesValue = recentPages.value ?? [];
            recentPagesValue.push({
                type: VisitedPageType.mfh,
                url: route.fullPath,
                route: {
                    name: route.name,
                    params: route.params,
                    query: route.query,
                },
                visitedAt: Date.now(),
                visitedMenus: labels,
                data: {
                    mfh: {
                        mfhId: mfh.value?.mfhId,
                        condoUuid: mfh.value?.condoUuid,
                    },
                },
            });
            setRecentPages(recentPagesValue);
        }
        flush();
    };

    const logInvoicePage = (route, invoice: IInvoice) => {
        const [recentPages, setRecentPages] = useStorage<IRecentlyVisitedPageDto[]>('RECENT_PAGES');

        const recentPagesValue = recentPages.value ?? [];
        recentPagesValue.push({
            type: VisitedPageType.invoice,
            url: route.fullPath,
            route: {
                name: route.name,
                params: route.params,
                query: route.query,
            },
            visitedAt: Date.now(),
            visitedMenus: ['Invoice'],
            data: {
                invoice: {
                    invoiceId: invoice.invoiceId,
                },
            },
        });
        setRecentPages(recentPagesValue);
        flush();
    };

    const logGeneralPage = route => {
        const [recentPages, setRecentPages] = useStorage<IRecentlyVisitedPageDto[]>('RECENT_PAGES');

        const recentPagesValue = recentPages.value ?? [];
        recentPagesValue.push({
            type: VisitedPageType.general,
            url: route.fullPath,
            route: {
                name: route.name,
                params: route.params,
                query: route.query,
            },
            visitedAt: Date.now(),
            visitedMenus: route.name?.split('.').map(s => s.charAt(0).toUpperCase() + s.slice(1)) ?? [],
            data: {},
        });
        setRecentPages(recentPagesValue);
        flush();
    };

    return { logEstatePage, logMfhPage, logInvoicePage, logGeneralPage };
};
