import { setEventToNoon } from '@/shared';
import { bulmaColorRGB, processMap } from '@/shared/color-mappers';
import type { IMFHCalendarEvent, ProcessType } from '@condo/domain';
import { MFHCalendarType } from '@condo/domain';
import type { EventInput } from '@fullcalendar/core';
import dayjs from 'dayjs';

export const EVENT_POPOVER_EVENT = 'EVENT_POPOVER_EVENT';

export enum EventPopOverAction {
    Delete = 'Delete',
    Edit = 'Edit',
    Close = 'Close',
}

export interface IFullMfhCalendarExtendedProps {
    raw?: { mfhId: any };
    calendarId?: number;
    description?: any;
    location?: any;
    attendees?: any;
    calendarName?: MFHCalendarType;
    useEstateLocation?: boolean;
    calendar?: { borderColor: any; color: string; bgColor: any; name: MFHCalendarType; dragBgColor: any; id: number };
    mfhId?: number;
    condoUuid?: string;
    calendarType?: MFHCalendarType;
    calendarEventId?: number;
    processType?: ProcessType;
}

export interface ICondoFullMfhCalendarEvent extends EventInput, IFullMfhCalendarExtendedProps {}

export const generalCalendarDefinition = {
    id: 0,
    name: MFHCalendarType.General,
    color: '#ffffff',
    bgColor: bulmaColorRGB.purple,
    dragBgColor: bulmaColorRGB.purple,
    borderColor: bulmaColorRGB.purple,
};

export const calendarList = [
    {
        id: 1,
        name: MFHCalendarType.Sourcing,
        color: '#ffffff',
        bgColor: processMap[MFHCalendarType.Sourcing].color,
        dragBgColor: processMap[MFHCalendarType.Sourcing].color,
        borderColor: processMap[MFHCalendarType.Sourcing].color,
    },
    {
        id: 2,
        name: MFHCalendarType.Purchasing,
        color: '#282828',
        bgColor: processMap[MFHCalendarType.Purchasing].color,
        dragBgColor: processMap[MFHCalendarType.Purchasing].color,
        borderColor: processMap[MFHCalendarType.Purchasing].color,
    },
    {
        id: 3,
        name: MFHCalendarType.Renovation,
        color: '#282828',
        bgColor: processMap[MFHCalendarType.Renovation].color,
        dragBgColor: processMap[MFHCalendarType.Renovation].color,
        borderColor: processMap[MFHCalendarType.Renovation].color,
    },
    {
        id: 4,
        name: MFHCalendarType.Selling,
        color: '#ffffff',
        bgColor: processMap[MFHCalendarType.Selling].color,
        dragBgColor: processMap[MFHCalendarType.Selling].color,
        borderColor: processMap[MFHCalendarType.Selling].color,
    },
    generalCalendarDefinition,
];

export const getGoogleCalendarSubscriptionLink = (account: string) =>
    `https://calendar.google.com/calendar/render?cid=https://calendar.google.com/calendar/ical/${account}/public/basic.ics`;

export const getCalendarIdByProcessType = calendarType => (calendarType ? calendarList.find(cal => cal.name === calendarType) : generalCalendarDefinition);

export const getProcessByCalendarId = (id: number) => {
    const calendar = calendarList.find(cal => +cal.id === +id);
    return calendar?.name ?? 'General';
};

export const constructRequestObj = (data: ICondoFullMfhCalendarEvent, mfhId: number) => {
    const condoFields = data.extendedProps ?? data;

    return {
        start: data.start,
        end: data.end,
        isAllDay: data.isAllDay,
        confirmed: data.confirmed,
        attendees: condoFields.attendees,
        title: data.title,
        location: condoFields.location,
        description: condoFields.description,
        mfhId,
        calendarType: condoFields.calendar?.name || getProcessByCalendarId(condoFields.calendarId),
        calendarEventId: condoFields.calendarEventId || data.id,
        useMfhLocation: condoFields.useMfhLocation,
    };
};

export const createNewEventObject = (start: Date, end?: Date): ICondoFullMfhCalendarEvent => ({
    id: null,
    allDay: false,
    start,
    end: end ?? dayjs(start).add(1, 'h').toDate(),
    calendarId: generalCalendarDefinition.id,
});

export const condoEventToFullCalendar = (event: IMFHCalendarEvent, mfhId: number): ICondoFullMfhCalendarEvent => {
    const calendar = getCalendarIdByProcessType(event.calendarType);

    return {
        id: `${event.calendarEventId}`,
        allDay: event.isAllDay,
        confirmed: event.confirmed,
        start: event.start,
        end: event.end,
        title: event.title,
        editable: true,
        backgroundColor: calendar.bgColor,
        /*All Condo Related data goes here*/
        raw: { mfhId },
        calendarId: calendar.id,
        description: event.description,
        location: event.location,
        attendees: event.attendees,
        calendarName: calendar.name,
        calendar,
        calendarEventId: event.calendarEventId,
        mfh: event.mfh,
    };
};

export const setToNoon = (data: ICondoFullMfhCalendarEvent, start, end) => {
    data.start = setEventToNoon(start);
    data.end = setEventToNoon(end);
};

export const fullCalendarToForm = (data: ICondoFullMfhCalendarEvent) => {
    return {
        calendarEventId: data.id,
        start: data.start,
        end: data.end,
        title: data.title,
        //calendarId: this.data.extendedProps.calendarId,
        location: data?.location,
        attendees: data?.attendees,
        isAllDay: data.allDay,
        description: data?.description,
        confirmed: data?.confirmed,
    };
};
