import { useQuery } from '@tanstack/vue-query';

import { fetchUsers } from '@/api/basecamp.api';
import { IContactPerson } from '@condo/domain';
import { computed } from 'vue';
import { store } from '../store';

export const useUsers = () => {
    return useQuery(['users'], fetchUsers, {
        initialData: [],
    });
};

// @note this is always set as it is set during bootstrap phase
export const useSelfUser = () => {
    return computed(() => store.getters.user as IContactPerson);
};
