import { MaybeRef, type Ref, computed, unref } from 'vue';

import { fetchActiveConfigRecords, fetchConfigById, fetchConfigHistory } from '@/api/basecamp.api';
import { store } from '@/store';
import type { IConfigObject } from '@condo/domain';
import { useQuery } from '@tanstack/vue-query';

import type { ICockpitConfig } from '@/types';

/**
 * Config object from server side
 *
 * @note This is always set as it is loaded during bootstrapping phase
 */
export const useRootAppConfig = () => computed<ICockpitConfig | undefined>(() => store.getters['generalData/config']);

/**
 * Config object from DB which is used to perform calculations
 * @returns {ComputedRef<IConfigObject | undefined>}
 */
export const useRootCalculationConfig = () => computed<IConfigObject | undefined>(() => store.getters['dictionaries/dictionaries']?.rootConfig);

export const useConfig = (configIdOrRef: string | Ref<string>) => {
    const configId = computed(() => unref(configIdOrRef));

    return useQuery(['configs', configId], async () => fetchConfigById(configId.value));
};

export const useActiveConfigs = () => {
    return useQuery(['configs/all'], async () => fetchActiveConfigRecords(), {
        initialData: [],
    });
};

export const useConfigHistory = (attributePath: MaybeRef<string>) => {
    const attrPath = computed(() => unref(attributePath));

    return useQuery(['config-history', attrPath], async () => fetchConfigHistory(attrPath.value));
};
