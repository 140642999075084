import '@/assets/scss/all.scss';
import { bootstrapComponents, configureAppInstance, getUserDetails, registerThirdPartyComponents } from '@/bootstrap';
import { initSentry } from '@/bootstrap/sentry';
import { router as externalRouter } from '@/external/external.routes';
import { router } from '@/router';
import { authManager } from '@/shared/auth-manager';
import { store } from '@/store';
import type { ICockpitConfig } from '@/types';
import '@condo/ui/build/style.css';
import { createGtm } from '@gtm-support/vue-gtm';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createHead } from '@unhead/vue';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utcPlugin from 'dayjs/plugin/utc';
import { initializeApp } from 'firebase/app';
import { createApp, defineAsyncComponent } from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { registerAgGridExtensions } from '@/bootstrap/ag-grid.init';
import { consoleError } from '@/bootstrap/error-wrapper';
import { ExternalContactTypes, IUser } from '@condo/domain';
import { isNil } from 'lodash-es';
import { initHttpClients, initTypesenseClient } from './api/api-client';
import { initI18n } from './i18n/init-i18n';

dayjs.extend(utcPlugin);
dayjs.extend(advancedFormat);
consoleError();

/*+2*/
const loadMainApp = async (user: IUser, configObj: ICockpitConfig, isProduction: boolean) => {
    // when a user hard-refreshes via index.html, remove all occurrences of cache-bust-logic
    if (location.search.includes('cache-bust')) {
        history.replaceState({}, document.title, '/');
    }
    const MainApp = defineAsyncComponent(() => import('@/root-containers/MainApp.vue'));
    const head = createHead();
    const app = createApp({
        template: '<MainApp/>',
        components: { MainApp },
        provide: {},
        setup() {},
    });

    app.provide('enableGPTImportsFeature', configObj.F_GPT_IMPORTS);
    app.provide('enableNetSuiteExportFeature', configObj.F_NETSUITE_INVOICE_EXPORT);
    /* Inits sentry error tracking */
    initSentry(configObj, app);
    initI18n(app);
    app.use(router);
    app.use(store);
    app.use(head);

    app.use(Toast, { shareAppContext: true });
    /* vue-query */
    app.use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: 0,
                },
            },
        },
    });

    /*Register and setup UI components*/
    bootstrapComponents(app);
    /*Third party libraries here*/
    registerThirdPartyComponents(app);
    /*Any extension for main app instance goes here*/
    configureAppInstance(app);

    await store.dispatch('dictionaries/fetchBootstrapData');

    if (configObj.GTM_ENABLED && configObj.GTM_ID?.length) {
        app.use(
            createGtm({
                id: configObj.GTM_ID,
                defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
                compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
                enabled: !!configObj.GTM_ENABLED, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                debug: !isProduction, // Whether or not display console logs debugs (optional)
                loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
                ignoredViews: [], // Don't trigger events for specified router names (optional)
                trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
            }),
        );
    }

    const initialRouteIsMFH = localStorage.getItem('CCS_INITIAL_ROUTE');
    const nextRoute = initialRouteIsMFH ?? configObj.DEFAULT_ROUTE;
    if (location.pathname === '/' && nextRoute?.length) {
        router.replace(nextRoute);
    }

    return app;
};

const loadExternalApp = (configObj: ICockpitConfig, isProduction: boolean) => {
    if (location.search.includes('cache-bust')) {
        history.replaceState({}, document.title, '/');
    }
    const ExternalApp = defineAsyncComponent(() => import('@/external/ExternalApp.vue'));
    const head = createHead();
    const app = createApp({
        template: '<ExternalApp/>',
        components: { ExternalApp },
        provide: {},
        setup() {},
    });

    initSentry(configObj, app);
    initI18n(app);
    app.use(externalRouter);
    app.use(store);
    app.use(head);

    app.use(Toast, { shareAppContext: true });
    /* vue-query */
    app.use(VueQueryPlugin, {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: 0,
                },
            },
        },
    });

    /*Register and setup UI components*/
    bootstrapComponents(app);
    /*Third party libraries here*/
    registerThirdPartyComponents(app);
    /*Any extension for main app instance goes here*/
    configureAppInstance(app);

    if (configObj.GTM_ENABLED && configObj.GTM_ID?.length) {
        app.use(
            createGtm({
                id: configObj.GTM_ID,
                defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
                compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
                enabled: !!configObj.GTM_ENABLED, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                debug: !isProduction, // Whether or not display console logs debugs (optional)
                loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
                ignoredViews: [], // Don't trigger events for specified router names (optional)
                trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
            }),
        );
    }

    return app;
};

const loadLoginApp = (configObj: ICockpitConfig) => {
    const LoginApp = defineAsyncComponent(() => import('@/root-containers/Login.vue'));
    const app = createApp({
        template: '<LoginApp :config="config"/>',
        data() {
            return { config: configObj };
        },
        components: {
            LoginApp,
        },
    });
    initSentry(configObj, app);
    initI18n(app);
    app.use(store);
    bootstrapComponents(app);

    return app;
};

const isExternalUser = (configObj: ICockpitConfig, user: IUser) => {
    if (!isNil(configObj.DEBUG_IS_EXTERNAL_USER)) {
        console.log(`Bypassing external user check due to DEBUG_IS_EXTERNAL_USER flag (External: ${configObj.DEBUG_IS_EXTERNAL_USER})`);
        return configObj.DEBUG_IS_EXTERNAL_USER;
    }

    return ExternalContactTypes.includes(user.contactType);
};

(async () => {
    registerAgGridExtensions();
    const isProduction = (import.meta as Record<string, any>).env.MODE === 'production';
    const response = await fetch('/config');
    const configObj: ICockpitConfig = await response.json();

    // base-check against latest cockpit version, does a full refresh when it changes
    const prevVersion = localStorage.getItem('__VERSION__');
    if (isProduction && prevVersion && configObj.HOUSEHOLD_VERSION !== prevVersion) {
        location.reload();
    }

    localStorage.setItem('__VERSION__', configObj.HOUSEHOLD_VERSION);

    initializeApp({ apiKey: configObj.FBASE_API_KEY, authDomain: configObj.FBASE_AUTH_DOMAIN, projectId: configObj.FBASE_PROJECT_ID });

    const firebaseUser: any = await authManager.getUserData();
    if (!firebaseUser) {
        loadLoginApp(configObj).mount('#app');
        return;
    }

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    store.commit('setUser', firebaseUser);
    store.commit('generalData/setIsMobile', isMobile);
    store.commit('generalData/setConfig', configObj);
    store.commit('ui/setSystemVersion', configObj.HOUSEHOLD_VERSION);
    store.commit('ui/setEnvironment', configObj.ENVIRONMENT_NAME);
    initHttpClients(store);

    const ccsUser = await getUserDetails(store);

    initTypesenseClient(store);

    if (isExternalUser(configObj, ccsUser)) {
        loadExternalApp(configObj, isProduction).mount('#app');
        return;
    }

    await loadMainApp(ccsUser, configObj, isProduction).then(app => {
        app.mount('#app');
    });
})();
