import { getClient } from '@/api/api-client';
import { findRentingEstates } from '@/api/renting.api';
import { type IRentingEstate, type IRentingLead, type IRentingListing, type IS24RentingListingAttachment, RentingLead, RentingListing } from '@condo/domain';
import type {
    CreateRentingListingMutationRequest,
    FindRentingLeadsQueryParams,
    GetRentingListingQueryParams,
    InvokeRentingListingActionPathParams,
} from '@condo/sdk/propx';
import { omit, uniqBy } from 'lodash-es';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export type RentingListingAttachments = {
    attachments: IS24RentingListingAttachment[];
    order: string[];
};

export interface IRentingStore {
    rentingListings: RentingListing[];
    rentingListing: RentingListing;
    rentingListingAttachments: RentingListingAttachments;
    rentingLeads: RentingLead[];
    estates: IRentingEstate[];
    total: number;
}

const getInitialState = (): IRentingStore => ({
    rentingListings: [],
    rentingListing: null,
    rentingLeads: [],
    rentingListingAttachments: {
        attachments: [],
        order: [],
    },
    estates: [],
    total: 0,
});

const state = getInitialState();

const getters: GetterTree<IRentingStore, any> = {
    rentingListings(state: IRentingStore): RentingListing[] {
        return state.rentingListings;
    },
    rentingListingAttachments(state: IRentingStore): RentingListingAttachments {
        return state.rentingListingAttachments;
    },
    rentingLeads(state: IRentingStore): RentingLead[] {
        return state.rentingLeads;
    },
    estates(state) {
        return state.estates;
    },
    total(state) {
        return state.total;
    },
};

const actions: ActionTree<IRentingStore, any> = {
    async getRentingLeads({ commit }, params: FindRentingLeadsQueryParams) {
        return getClient('propx')
            .findRentingLeads(params)
            .then(data => {
                const rentingLeads = data.rentingLeads.map(r => new RentingLead(r));
                commit('setRentingLeads', rentingLeads);
                return rentingLeads;
            });
    },
    async getRentingListing({ commit }, params: GetRentingListingQueryParams) {
        const data = await getClient('propx').getRentingListing(params);
        const rentingListing = data ? new RentingListing(data as IRentingListing) : null;
        commit('setRentingListing', rentingListing);
        return rentingListing;
    },
    async getRentingListingAttachments({ commit }, rentingListingId: number) {
        const [{ attachments }, { attachmentsOrder: order }] = await Promise.all([
            getClient('propx').getRentingListingAttachments({ rentingListingId }),
            getClient('propx').getRentingListingAttachmentsOrder({ rentingListingId }),
        ]);
        commit('setRentingListingAttachments', { attachments, order });
        return { attachments, order };
    },
    async updateRentingListing({ commit }, params: IRentingListing) {
        const data = await getClient('propx').updateRentingListing({ rentingListingId: params.rentingListingId }, { rentingListing: params });
        const rentingListing = new RentingListing(data as IRentingListing);
        commit('setRentingListing', rentingListing);
        return rentingListing;
    },
    async createRentingListing({ commit }, params: CreateRentingListingMutationRequest['rentingListing']) {
        const data = await getClient('propx').createRentingListing({ rentingListing: params });
        const rentingListing = new RentingListing(data as IRentingListing);

        commit('setRentingListing', rentingListing);
        return rentingListing;
    },

    async invokeRentingListingAction({ commit }, params: InvokeRentingListingActionPathParams) {
        const data = await getClient('propx').invokeRentingListingAction(params);
        const rentingListing = new RentingListing(data.rentingListing as IRentingListing);
        commit('setRentingListing', rentingListing);
        return rentingListing;
    },
    async getRentingEstates({ commit }, params) {
        const response = await findRentingEstates(params);
        const { estates, total } = response;
        commit('setEstates', { estates, total });

        return estates;
    },
    async saveRentingLead({ commit }, entity: IRentingLead | RentingLead): Promise<RentingLead> {
        const { rentingLead } = await getClient('propx').saveRentingLead({ rentingLead: omit(entity, ['createdAt', 'updatedAt', 'addedUserId']) });
        commit('addRentingLead', rentingLead);
        return new RentingLead(rentingLead as IRentingLead);
    },
    async removeRentingLead({ commit }, rentingLeadId: number) {
        getClient('propx')
            .deleteRentingLeadById({ rentingLeadId })
            .then(() => commit('removeRentingLead', rentingLeadId));
    },
};

const mutations: MutationTree<IRentingStore> = {
    setEstates(state, { estates, total }) {
        state.estates = estates;
        state.total = total;
    },
    setRentingLeads(state: IRentingStore, rentingLeads: RentingLead[]) {
        state.rentingLeads = rentingLeads;
    },
    setRentingListing(state: IRentingStore, rentingListing: RentingListing) {
        state.rentingListing = rentingListing;
    },
    setRentingListingAttachments(state: IRentingStore, rentingListingAttachments: RentingListingAttachments) {
        state.rentingListingAttachments = rentingListingAttachments;
    },
    addRentingLead(state: IRentingStore, rentingLead: RentingLead) {
        state.rentingLeads = uniqBy([rentingLead, ...state.rentingLeads], rl => rl.rentingLeadId);
    },
    removeRentingLead(state: IRentingStore, rentingLeadId: number) {
        state.rentingLeads.splice(
            state.rentingLeads.findIndex(r => r.rentingLeadId === rentingLeadId),
            1,
        );
    },
};

export const rentingStore: Module<IRentingStore, any> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
